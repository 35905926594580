<template>
  <div
    ref="root"
    class="fixed right-0 top-2/3 z-20 flex translate-x-[calc(100%-3.6rem)] transition-transform hover:translate-x-[calc(100%-5rem)] has-[[aria-expanded='true']]:-translate-x-2 hover:has-[[aria-expanded='true']]:-translate-x-2 tablet:top-1/3"
    :class="{ bounce: mounted && !hasBounced && !isEditor && !opened }"
  >
    <div role="tablist">
      <button
        :id="`flyout-${label}`"
        class="translate-x-0.5 rounded-l-double border-2 border-r-0 border-text bg-back-accent p-2.5 text-3xl"
        role="tab"
        :aria-expanded="opened"
        :aria-controls="`flyout-${label}-panel`"
        :aria-label="label"
        @click="opened = !opened"
      >
        <nuxt-icon :name="icon" class="[&>svg]:!mb-0" />
      </button>
    </div>
    <div
      :id="`flyout-${label}-panel`"
      class="flex flex-col rounded-r-double rounded-bl-double border-2 border-text bg-back-accent p-2 pl-0 pr-4"
      role="tabpanel"
      :tabindex="opened ? 0 : -1"
      :aria-labelledby="`flyout-${label}`"
      @click="ev => (opened ? undefined : ((opened = true), ev.preventDefault()))"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { isEditorKey } from "~/injection-symbols"

const props = defineProps<{ label: string; icon: string; }>()

const isEditor = inject(isEditorKey, false)
const hasBounced = useSessionStorage<boolean>(`flyout-initial-opened-${props.label}`, false)
const opened = ref(false)
const mounted = ref(false)
const root = ref<HTMLDivElement>()

onClickOutside(root, () => (opened.value = false))

onMounted(() => {
  if (!hasBounced.value) {
    mounted.value = true
    setTimeout(() => hasBounced.value = true, 1000)
  }
})
</script>

<style scoped lang="scss">
.bounce {
  animation: bounce 1s forwards ease-in-out;
}

@keyframes bounce {
  0%, 50%, 100% {
    @apply right-0;
  }
  25%, 75% {
    @apply right-12;
  }
}
</style>
